import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import moment from 'moment';
import { Line,Bar } from 'react-chartjs-2'
import CloseIcon from '@mui/icons-material/Close';
import { address } from '../settings';

import '../Admin/timestatistic.css'

const TimeStatistic = () => {
    const token = Cookies.get('_auth')
    const [loading,setLoading] = useState(true)
    const [users,setUsers] = useState([])
    const [graphLoading,setGraphLoading] = useState(false)
    const [KeyArray,setKeyArray] = useState([])
    const [mas,setMas] = useState([])
    const [ValArray,setValArray] = useState([])
    const [selectedUsers,setSelectedUsers] = useState({})
    const [backupUsers,setBackupUsers] = useState([])
    const organizationHandler = (e) =>{
        let masiv = []
        masiv = [...backupUsers].filter((item)=>item.organization.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1||item.full_name.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1)
        setUsers(masiv)
    }
    const clearOrganization = () =>{
        document.getElementById('organization').value=''
        setUsers(backupUsers)
    }
    useEffect(()=>{
        let date = new Date().toISOString()
        let obj = {}
        document.getElementById('start').value=moment().startOf('month').format("YYYY-MM-DD HH:mm:ss")
        document.getElementById('end').value=moment().format("YYYY-MM-DD HH:mm:ss")
        fetch(address+'/users/for/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
            if(result!==false){
            setUsers(result)
            setBackupUsers(result)
            setLoading(false)
            result.map((item)=>obj[`${item.id}`]=false)
            setSelectedUsers(obj)
          }
            else alert("Неверно введённые данные")
        })
    },[])
    const sel = () =>{
        var val = document.getElementById('selectall').checked
        let obj ={}
        Object.keys(selectedUsers).map(item=>parseInt(item)).map(item=>obj[item]=val)
        setSelectedUsers(obj)
    }
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('ad-select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD HH:mm:ss');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD HH:mm:ss');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD HH:mm:ss');
                break;
            default:break
        }
    }
    const handleSelect = () => {//Обработчик изменений полей для ввода даты
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('ad-select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('ad-select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('ad-select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('ad-select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('ad-select').value='previous_quarter'
        else document.getElementById('ad-select').value='random'
    }
    const getResearches = () =>{
        let start = moment(document.getElementById('start').value).format("YYYY-MM-DD HH:mm:ss")
        let end = moment(document.getElementById('end').value).format("YYYY-MM-DD HH:mm:ss")
        if(start==end){alert('Начало и конец период не должны совпадать'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        if(document.getElementById('start').value>document.getElementById('end').value){
            alert('Конец периода не может превышать начало');
            document.getElementById('end').value=document.getElementById('start').value;
            setGraphLoading(false);
            return 0
        }
        setGraphLoading(true)
        fetch(address+'/get/users/researches/time', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:'{"id_mas":{"mas":['+mas+']},"mode":"comparative","date_start":"'+start+'","date_end":"'+end+'"}'})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setValArray(result.data)
                        setKeyArray(result.keys)
                        setMas(result.len)
                        setGraphLoading(false)
                    })
    }
    return (
        <div className='admin-time'>
            <div className='admin-stat-table'>
                <div className='admin-stat-table-title'>
                    <div className='admin-stat-table-title-text'>Пользователи</div>
                </div>
                <div className='admin-stat-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='stat-table-data'>
                            <table className='ad-stat-table'>
                                <thead className='ad-stat-thead'>
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th'><input type='checkbox'  onChange={()=>sel()} id='selectall'/></th>
                                        <th className='stat-tr-th' id='stat-tr-th'><input id='organization' onBlur={()=>document.getElementById('organization').placeholder='Организация'} onFocus={()=>document.getElementById('organization').placeholder=''} type='text' placeholder='Организация' onChange={organizationHandler}/><CloseIcon className='close-icon' title='Очистить поле' sx={{color:'white',fontSize:'14'}} onClick={()=>clearOrganization()}/></th>
                                        <th className='stat-tr-th'>Логин</th>

                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody'>
                                    {users&&users.length===0&&(
                                        <tr className='ad-stat-tr'>
                                            <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                        </tr>
                                    )}
                                    {users&&users.map((item)=>
                                        <tr key={item.id} className='ad-stat-tr' onClick={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})}>
                                            <td className='stat-tr-td'><input id = {item.id} checked={selectedUsers[`${item.id}`]} onChange={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})} type='checkbox' name='input'/></td>
                                            <td className='stat-tr-td'>
                                                <div className="stat-tr-td-div">
                                                    <div className='stat-tr-td-info'>
                                                        {item.organization}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='stat-tr-td'>{item.login}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className='ad-stat-select'>
                    <select id='ad-select' onChange={()=>handleChange()}>
                        <option value='default'>Выбрать период</option>
                        <option value='current_week'>Текущая неделя </option>
                        <option value='last_week'>Прошлая неделя </option>
                        <option value='current_month'>Текущий месяц </option>
                        <option value='previous_month'>Прошлый месяц </option>
                        <option value='current_quarter'>Текущий квартал </option>
                        <option value='previous_quarter'>Предыдущий квартал</option>
                        <option value='current_year'>Текущий год </option>
                        <option value='previous_year'>Прошлый год </option>
                        <option value='random'>Произвольный период </option>
                    </select>
                </div>
                <div className='admin-stat-table-date'>
                    <input className="admin-stat-table-date-input" id = 'start' type="datetime-local" onChange={()=>handleSelect()}/>
                    <input className="admin-stat-table-date-input" id = 'end' type="datetime-local" onChange={()=>handleSelect()}/>
                </div>
                <div className='admin-stat-table-date-send'>
                    <button onClick={()=>getResearches()}>Отобразить данные</button>
                </div>

            </div>
            <div className='admin-stat-diagram'>
                <div className='admin-stat-diagram-title'>
                    <div className='admin-stat-diagram-title-container-1'>
                        <div className='admin-stat-diagram-title-text'>
                            Статистика
                        </div>
                    </div>
                </div>
                <div className='admin-stat-diagram-data'>
                    {graphLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):
                    (
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: KeyArray,
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000ffffff ',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                label:'Среднее время обработки, с'
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:'Среднее время обработки, с'
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:'Дата'
                                        },
                                    }
                                },
                                plugins: {
                                    tooltip: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        bodyFontColor: '#fff',
                                        titleFontColor: '#fff',
                                        callbacks: {
                                            title: function(context) {
                                                if(context[0].dataIndex==0)return KeyArray[context[0].dataIndex]
                                                return 'Период: '+KeyArray[context[0].dataIndex-1]+' - '+KeyArray[context[0].dataIndex]+'\nКоличество исследований: '+mas[context[0].dataIndex]
                                            },
                                            
                                        },
                                    },
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default TimeStatistic;